.navbar
  // height: 100%
  // background-color: #fff !important
  position: fixed
  // width: 83px
  z-index: 99
  top: 0
  @media screen and(max-width: 768px)
    right: 0
.nav-link:focus, .nav-link:hover
  color: #fff

.offcanvas-header
  background-color: #cccccc !important
.offcanvas-start
  width: 300px
.offcanvas-body
  background: #e7e7e7
  padding-left: 30px
  a
    color: #fff
    text-decoration: none
    padding: 10px 0
    display: inline-block
    transition: .3s
    position: relative
    z-index: 1
    position: relative
    margin: 5px 0

  .active
    color: #fff
