.pages-brief
  background: url(../../images/patern1.svg)
  form
    margin-top: 40px
  .h1
    margin-bottom: 25px
  .title
    font-size: 22px
    font-weight: bold
    margin-top: 20px
  .p
    margin-bottom: 0
  .sub
    font-size: 14px
    color: red
  .checkbox
    width: 25px
    height: 25px
    cursor: pointer
  .logo
    padding: 15px 0
    height: 100px
  .button-submit
    margin-top: 40px
    margin-bottom: 40px
    padding: 15px
    max-width: 350px
    width: 100%
.linkField
  outline: none
  border: none
  max-width: 100%
  width: 100%
.error
  color: #000
  height: 200px
  margin: -100px 0 0 -340px
  font-family: Arial, sans-serif
  text-align: center
  position: absolute
  top: 50%
  text-align: center
  left: 50%
  width: 680px
  &-fon
    font-size: 240px
    font-weight: bold
    line-height: 240px
    color: #f5f5f5
    position: absolute
    top: -16px
    left: 50%
    margin-left: -200px
    z-index: -1
    &-text-top
      font-size: 40px
      margin-top: 50px
      &-test-separate
        border-top: 1px solid #f2f2f2
        margin-top: 10px
      &-text-bottom
        font-size: 20px
        margin-top: 16px
.linkEdit
  display: flex
  justify-content: center
