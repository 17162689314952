.button
  width: 100%
  max-width: 80px

.client
  display: flex
  align-items: center
  margin-bottom: 10px
.client2
  display: flex
  align-items: center
  margin-bottom: 20px
.client3
  display: flex
  align-items: center

.input
  width: 20px
  height: 20px
  cursor: pointer
  margin-left: 30px
.label
  margin-left: 8px
  display: block
  font-size: 14px
  cursor: pointer

.label2
  margin-left: 8px
  display: block
  font-size: 16px
  cursor: pointer
  font-weight: bold
  text-decoration: underline
.label3
  margin-left: 8px
  display: block
  font-size: 12px
  cursor: pointer

.ul
  columns: 2
.ul2
  columns: 3
  padding: 0

.li
  list-style: none
  position: relative
.closeButton
  right: auto
  left: 0
.textarea
  width: 100%
  height: 80px
  min-height: 40px
  padding: 6px 8px
  border: 1px solid #DEDEDE

.previewMessage
  border: 1px solid #DEDEDE
  border-radius: 3px
  overflow: hidden
  background: url(../../images/telegram-fon.jpg)
  img
    width: 70%
    border-radius: 10px
    margin: 10px
  p
    padding: 15px
    background: #fff
    margin: 10px
    border-radius: 10px
    display: table
.buttonSend
  margin-top: 20px
.noClientsFieldBox
  background: #eee
  padding: 5px
  textarea
    width: 100%
    height: 40px
    min-height: 40px
    padding: 6px 8px
    border: 1px solid #DEDEDE
