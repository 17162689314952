.inputSelect
  position: relative
  width: 100%
  @media screen and (max-width: 991px)
    max-width: 100%
  input
    width: 100%
    max-width: 100%
    height: 40px
    padding: 0 8px
    border: 1px solid #DEDEDE
    box-sizing: border-box
    border-radius: 3px
    font-size: 14px
  .ul
    background: #fff
    position: absolute
    z-index: 11
    padding: 0
    overflow: hidden
    border-radius: 3px
    border: 1px solid #d3d3d3
    box-sizing: border-box
    box-shadow: 0px 5px 25px rgba(0,0,0,.2)
    transform: translateY(5px)
    overflow-y: auto
    max-height: 300px
    width: 100%
  .li
    list-style: none
    padding: 10px 25px
    transition: .3s
    cursor: pointer
    font-size: 14px
    &:hover
      background: #FF0000
      color: #fff
  .active
    background: #FF0000
    color: #fff
  .inputSelectBut
    position: absolute
    top: 0
    bottom: 0
    margin: auto
    right: 0px
    cursor: pointer
    width: 44px
    display: flex
    justify-content: center
    align-items: center
  .noSearch
    color: #c2c2c2
    &:hover
      background: none
      color: #c2c2c2
.isDisabled
  opacity: .8
