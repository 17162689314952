.input
  border: 0px solid black
  width: 100%
  height: 40px
  border: 1px solid #DEDEDE
  border-radius: 3px
.label
  display: block
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 19px
  color: #7B7B7B
  margin-bottom: 5px
.simpleInput
  text-align: center
.selectInput
  text-align: center
  height: 40px
  border: 1px solid #DEDEDE
  cursor: pointer
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
