.ul
  columns: 3
  list-style: none
  label
    display: flex
    align-items: center
    margin-bottom: 8px
    cursor: pointer
    p
      margin: 0
    input
      min-width: 25px
      margin-right: 10px
