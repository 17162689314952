@import './normalize'
::-webkit-scrollbar
  width: 10px
  height: 5px

::-webkit-scrollbar-track
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
  border-radius: 10px

::-webkit-scrollbar-thumb
  border-radius: 10px
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8)
.pages
  h1
    padding: 15px 0 25px 0
    @media screen and(max-width: 767px)
      padding: 65px 0 25px 0
.input-style
  position: relative
  label
    display: block
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 19px
    color: #7B7B7B
    margin-bottom: 5px
  input
    max-width: 100%
    width: 100%
    height: 40px
    padding: 0 8px
    border: 1px solid #DEDEDE
    box-sizing: border-box
    border-radius: 3px
    @media screen and (max-width: 991px)
      max-width: 100%
  textarea
    width: 100%
    min-height: 40px
    padding: 6px 8px
    border: 1px solid #DEDEDE
.inputSelectPhoneCode
  min-width: 100px
  max-width: 100px
.inputSelect,.inputSelectPhoneCode,.input-select
  input
    height: 23px

.input-checkbox
  position: relative
  max-width: 270px
  .select-day
    display: block
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #7B7B7B
    margin-bottom: 5px
  .input-box
    border: 1px solid #DEDEDE
    padding: 5px 3px
    box-sizing: border-box
    border-radius: 3px
    display: flex
    justify-content: space-between
    margin-bottom: 0
    input
      display: none
      position: absolute
      z-index: -1
    label
      height: 28px
      width: 100%
      display: block
      background: #e7e7e7
      border: 1px solid #a5a5a5
      box-sizing: border-box
      border-radius: 3px
      text-align: center
      line-height: 2
      margin: 0 3px
      cursor: pointer
      font-size: 14px
      &:last-child
        margin-right: none
    input:checked + label
      background: #FF0000
      color: #fff

*
  font-family: 'Roboto', sans-serif

.editForm
  background: #eee
  padding: 10px
  margin-bottom: 8px
  position: relative
.close-button
  font-size: 30px
  line-height: 0.5
  width: 16px
  height: 18px
  position: absolute
  top: 0
  right: 0
  bottom: 0
  margin: auto
  margin-right: 6px
  color: #ff0000
  cursor: pointer
  transition: .3s
  display: block
  overflow: hidden
  transform-origin: center
  &:hover
    transform: rotate(180deg)
.detailed-button
  font-size: 20px
  line-height: 0.5
  width: 18px
  height: 18px
  position: absolute
  left: 76px
  top: 8px
  margin: 5px
  color: #ff0000
  cursor: pointer
  transition: .3s
  display: block
  overflow: hidden
  transform-origin: center
  z-index: 1
  text-decoration: none
  &:hover
    transform: scale(1.3)
.formanimation-enter
  opacity: 0
  transform: translateX(600px)
.formanimation-enter-active
  opacity: 1
  transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1)
  transform: translateX(0px)
.formanimation-exit
  opacity: 1
  transform: translateX(0px)
.formanimation-exit-active
  opacity: 0
  transition: 0.6s cubic-bezier(0.45, 0, 0.55, 1)
  transform: translateX(-600px)
.buttonAnimation
  img
    transform-origin: center
    animation: pdfBuilding 1s linear infinite

.payment
  background: #eee
  margin: 8px 0
  border-radius: 3px
  padding: 10px
  position: relative
  .payment-who,.payment-price
    display: flex
    p
      padding: 5px 15px
      margin: 0
.red
  background: red
  color: #fff
.blue
  background: #0d6efd
  color: #fff
.tablefilterBut
  width: 30px
  height: 30px
  background: #0d6efd
  position: fixed
  right: 335px
  z-index: 1
  top: 0
  bottom: 0
  margin: auto
  cursor: pointer
  transition: .3s
  transform: translateX(350px) rotate(45deg)
  span
    color: #fff
    transform: rotate(-45deg)
    display: block
.tablefilterOpen
  transform: translateX(0px) !important
.tablefilterButOpen
  transform: translateX(0px) rotate(45deg)
#preloader
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  z-index: 11
  display: flex
  align-items: center
  justify-content: center
  background: #ffffff8e
  backdrop-filter: blur(4px)
  svg
    animation: pdfBuilding 1s linear infinite
  h2
    margin: 0 0 0 10px
.block
  box-shadow: 0px 0px 100px #CD6272 inset
  width: 23px
  height: 23px
.del
  box-shadow: 0px 0px 100px #000 inset
  width: 23px
  height: 23px
@keyframes pdfBuilding
  0%
    transform: rotate(360deg)
  100%
    transform: rotate(0deg)
.tabs-container
  margin-top: 50px
  .tab-buts
    display: flex
    border-bottom: 1px solid #DEDEDE
    .tab-but
      display: block
      color: #000
      text-decoration: none
      padding: 10px 0px 20px
      margin-right: 40px
      font-size: 18px
      transition: .3s
      &:last-child
        margin-right: 0
    .tab-but-active
      box-shadow: 0px -6px 0px #0d6efd inset
  .tab-pages
    border: 1px solid #DEDEDE
    border-top: 0
    border-radius: 0 0 6px 6px
    padding: 45px 0 5px
    margin-top: 0px
    .tab-page
      display: none
    .tab-page-active
      display: block
.listHead,.invoicesHead
  border-bottom: 1px solid #DCDCDC
  margin-top: 20px
  margin-bottom: 20px
  position: sticky
  top: 0
  background: #fff
  z-index: 10
  div
    font-size: 14px
    color: #7B7B7B
    padding: 5px 10px 0px
.listBody,.invoice
  position: relative
  &:nth-child(odd)
    background: #f3f3f3
  div
    font-size: 15px
    display: flex
    align-items: center
    a
      padding: 5px 8px
      color: #267eff
      text-decoration: none
      &:hover
        text-decoration: underline
.invoicesHead
  padding: 0 15px
  margin-top: -10px
.invoicesBody
  padding: 0 15px
.invoice
  input
    background: none
  div
    padding: 3px 8px
    a
      padding: 0
