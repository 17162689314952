.input
  border: 0px solid black
  width: 100%
  height: 40px
  border: 1px solid #DEDEDE
  border-radius: 3px
.label
  display: block
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 19px
  color: #7B7B7B
  margin-bottom: 5px
.simpleInput
  text-align: center
.selectInput
  text-align: center
  height: 40px
  border: 1px solid #DEDEDE
  cursor: pointer
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
.addReport
  position: absolute
  top: -120px
  left: 0
  right: 0
  z-index: 99
  background: #fff
  border: 1px solid #f5f5f5
  box-shadow: 0px 0px 15px rgba(0,0,0,.3)
  border-radius: 10px
.closeAddReport
  display: flex !important
  justify-content: flex-end
  cursor: pointer
  padding: 0 !important
  span
    display: block
    transition: .3s
  &:hover span
    transform: rotate(180deg)
