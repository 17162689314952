.tableFilter
  position: fixed
  top: 0
  max-width: 350px
  width: 100%
  height: 100vh
  background: #eee
  padding: 15px
  z-index: 2
  right: 0
  overflow: hidden
  transition: .3s
  // display: none
  transform: translateX(350px)
.tableFilterButton
  width: 100%
.tableBox
  height: 100%
  @media screen and(max-width: 420px)
    overflow-x: auto
.table
  border-collapse: collapse
  border: 1px solid black
.thead
  background: rgb(233, 233, 233)
  position: sticky
  z-index: 1
  top: 0
  width: 100%
  box-shadow: 0px 1px 0px #000
.tbody
  margin-top: 48px
  &:hover
    background: #000
.th
  font-size: 10px
  text-align: center
  padding: 5px 10px
  border: 1px solid black
  word-break: break-word
  position: relative
.td
  font-size: 12px
  border: 1px solid black
  position: relative
.tdsum
  font-size: 12px
  border: 1px solid black
  background: #c4c4c4
.input
  border: 0px solid black
  width: 100%
  height: 40px
  border: 1px solid #DEDEDE
.label
  padding-bottom: 5px
  font-size: 14px
  color: #5e5e5e
.simpleInput
  text-align: center
.selectInput
  text-align: center
  height: 40px
  border: 1px solid #DEDEDE
  cursor: pointer
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
.markerTh
  box-shadow: 0px 0px 0px 2px #000000 inset
.filterSumm
  position: fixed
  bottom: 0
  left: 0
  right: 0
  margin: auto
  width: 100px
  font-weight: 900
  background: #0d6efd
  color: #fff
  border-radius: 50% 50% 0 0
  text-align: center
  transition: .5s
  transform: translateY(50px)
.filterSummOpen
  transform: translateY(0px)
